import React, {useCallback, useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {utilsContext} from "../contexts";

// components

import ExportExcel from "../exports/serviceListExcel";
import CustomLoadingButton from "../components/InputFields/CustomLoadingButton";
import { getExportService } from "../api/service";

// material ui
import {
  Button,
  Typography,
  Divider
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import ServicePdf from '../exports/service';
import QuotationPdf from '../exports/quotation.';
import QuotationSupplierPdf from '../exports/quotationSupplier';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    alignItems: 'center',
    marginBottom: 12,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
      flexDirection: 'column-reverse'
    },
  },
  headerContentWrapper: {

  },
  headerButtonWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  pageTitle: {
    fontSize: theme.typography.pageTitleText.fontSize,
    color: '#4c4e55',
  },
  iconButton: {
    marginLeft: 10,
    color: '#1e86ff',
    backgroundColor: 'transparent',
    border: '1px solid #1e86ff',
    boxShadow: '0 3px 6px 0 rgba(131, 131, 131, 0.16)',
    fontSize: 12,
    textTransform: 'none',
    padding: '6px 16px',
  },
  icons: {
    marginRight: 5,
    fontSize: 18,
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      // bottom: theme.spacing(2),
      right: 0,
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  divider: {
    marginBottom: 15,
  }
}));

function CustomHeader(props) {
  const {title, customHead, excel, pdf, customComponent, details, serviceID} = props;
  const { _handleChange } = useContext(utilsContext);
  const classes = useStyles();
  const [isPrintService, setIsPrintService] = useState(false);
  const [isPrintQuotation, setIsPrintQuotation] = useState(false);
  const [isPrintQuotationSupplier, setIsPrintQuotationSupplier] = useState(false);
  const [isExportExcel, setIsExportExcel] = useState(false);
  const [excelFormat, setExcelFormat] = useState([]);
  const [printQuotationLoading, setPrintQuotationLoading] = useState(false);

  const handlePrintService = () => {
    setPrintQuotationLoading(true);
    setIsPrintService(true);
  }

  const handlePrintQuotation = () => {
    setPrintQuotationLoading(true);
    setIsPrintQuotation(true);
  }

  const handlePrintQuotationSupplier = () => {
    setPrintQuotationLoading(true);
    setIsPrintQuotationSupplier(true);
  }

  const handleExportExcel = async () => {
    const exportItem = [];
    details.items.forEach(e => {
      if (e.checked) exportItem.push(e.id)
    })

    // if (exportItem.length === 0) {
    //   details.items.forEach(e => {
    //     exportItem.push(e.id)
    //   })
    // }
    // if (exportItem.length === 0) {
    //   _handleChange({
    //     snackbar: true,
    //     snackbarType: 2,
    //     snackbarContent: 'No item to export',
    //   })
    //   return null;
    // }

    setPrintQuotationLoading(true);
    const {
      startDate, endDate,
      rowsPerPage, page, order, orderBy, 
      jobSheetNumber, customerNumber, cashSaleNumber,
      supplier, completeStatus, completeActive, repairProgress,
      repairBy, warrantyType, completeStartDate, completeEndDate
    } = details;

    let params = {
      orderBy: orderBy === 'real_id' ? 'id' : orderBy,
      orderType: order,
      startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
      endDate: endDate && moment(endDate).format('YYYY-MM-DD')
    };
    
    if (exportItem.length > 0) {
      params.serviceIds = JSON.stringify(exportItem);
    }
    else {
      params.number = jobSheetNumber;
      params.customerNumber = customerNumber;
      params.cashSale = cashSaleNumber;
      params.supplierId = supplier === 0 ? null : supplier;
      params.completeStatusId = completeActive ? completeStatus : null;
      params.repairProgressStatusId = repairProgress;
      params.repairById = repairBy === 0 ? null : repairBy;
      params.warrantyTypeId = warrantyType === 0 ? null : warrantyType;
      if (completeActive) {
        params.completeStartDate = completeStartDate && moment(completeStartDate).format('YYYY-MM-DD');
        params.completeEndDate = completeEndDate && moment(completeEndDate).format('YYYY-MM-DD');
      }
    };

    const result = await getExportService(params);
    
    const { success, data } = result;
    const failObj = {};
    if (success) {
      const excelFormat = data.details.serviceList.map((e, i) => {
        return {
          no: i+1,
          jobSheetNumber1: e.number1 || '',
          jobSheetNumber2: e.number2 || '',
          jobSheetNumber3: e.number3 || '',
          keyInDate: e.keyInDate ? moment(e.keyInDate).format('YYYY/MM/DD') : '',
          jobDate: e.jobDate ? moment(e.jobDate).format('YYYY/MM/DD') : '',
          customerName: e.customerName || '',
          customerPhoneNumber: e.customerPhoneNumber || '',
          customerPhoneNumber2: e.customerPhoneNumber1 || '',
          customerPhoneNumber3: e.customerPhoneNumber2 || '',
          customerAddress: e.customerAddress || '',
          productBrand: e.productBrand || '',
          modelCode: e.modelCode || '',
          repairer: e.repairBy || '',
          supplier: e.supplier || '',
          productType: e.productType || '',
          serialNumber: e.serialNumber || '',
          secondSerialNumber: e.secondSerialNumber || '',
          repairIn90Day: e.repairIn90Day ? 'Yes' : 'No',
          purchaseDate: e.purchaseDate ? moment(e.purchaseDate).format('YYYY/MMM/DD') : '',
          purchasePrice: e.purchasePrice || 0,
          warrantyType: e.warrantyType || '',
          serviceRepairProgressStatus: e.serviceRepairProgressStatus || '',
          progressDuration: e.progressDuration || '0',
          progressCreateTime: e.progressUpdateTime ? moment(e.progressUpdateTime).format('YYYY/MM/DD') : '',
          serviceCompleteStatus: e.serviceCompleteStatus || '',
          repairDuration: e.repairDuration || '',
          completeCreateTime: e.progressCompletedUpdateTime ? moment(e.progressCompletedUpdateTime).format('YYYY/MM/DD') : '',
          duration: e.duration || '',
          totalQuotationAmount: e.totalQuotationAmount || 0,
          totalQuotationAmountSupplier: e.totalQuotationAmountSupplier || 0,
          deposit: e.deposit || 0,
          depositSupplier: e.depositSupplier || 0,
        }
      })
      setExcelFormat(excelFormat);
    } else {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = 'Fail to get service list';
    }

    _handleChange({ openBackdrop: false, ...failObj });
    setIsExportExcel(true);
    setPrintQuotationLoading(false);

    setTimeout(function() {
      setIsExportExcel(false);
    }, 1000)
  }

  return (
    <React.Fragment>
      <div className={classes.headerWrapper}>
        {customHead ?
          customHead
          :
          <div className={classes.headerContentWrapper}>
            <Typography className={classes.pageTitle}>{title}</Typography>
          </div>
        }
        <div className={classes.headerButtonWrapper}>
          {customComponent && customComponent}
          {excel &&
            <CustomLoadingButton
              label='Export Excel'
              component={<PrintIcon className={classes.icons} />}
              loading={printQuotationLoading}
              handleClick={handleExportExcel}
            />

          }
          {pdf &&
            <CustomLoadingButton
              label='Service'
              component={<GetAppIcon className={classes.icons} />}
              loading={printQuotationLoading}
              setLoading={setPrintQuotationLoading}
              handleClick={handlePrintService}
            />
          }
          {pdf &&
            <CustomLoadingButton
              label='Quotation(Supplier)'
              component={<GetAppIcon className={classes.icons} />}
              loading={printQuotationLoading}
              setLoading={setPrintQuotationLoading}
              handleClick={handlePrintQuotationSupplier}
            />
          }
              {pdf &&
            <CustomLoadingButton
              label='Quotation(Customer)'
              component={<GetAppIcon className={classes.icons} />}
              loading={printQuotationLoading}
              setLoading={setPrintQuotationLoading}
              handleClick={handlePrintQuotation}
            />
          }
        </div>
      </div>
      <Divider className={classes.divider} />

      {isPrintService &&
        <PDFDownloadLink document={<ServicePdf details={details} serviceID={serviceID} />} fileName="service.pdf">
          {({ blob, url, loading, error }) => {
            if (!loading) {
              window.open(url);
              setIsPrintService(false);
              setPrintQuotationLoading(false);
            }
          }}
        </PDFDownloadLink>
      }

      {isPrintQuotation &&
      <PDFDownloadLink document={<QuotationPdf details={details} />} fileName="quotation.pdf">
        {({ blob, url, loading, error }) => {
          if (!loading) {
            window.open(url);
            setIsPrintQuotation(false);
            setPrintQuotationLoading(false);
          }
        }}
      </PDFDownloadLink>
      }

      {isPrintQuotationSupplier &&
      <PDFDownloadLink document={<QuotationSupplierPdf details={details} />} fileName="quotation.pdf">
        {({ blob, url, loading, error }) => {
          if (!loading) {
            window.open(url);
            setIsPrintQuotationSupplier(false);
            setPrintQuotationLoading(false);
          }
        }}
      </PDFDownloadLink>
      }

      {isExportExcel &&
        <ExportExcel items={excelFormat} />
      }

    </React.Fragment>
  )
}

export default CustomHeader;
