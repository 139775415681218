import React, {useEffect, useCallback, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

// components
import { utilsContext } from '../../contexts';
import {checkSpecialRole} from "../../utils";
import content from "./content.json";
import CustomHeader from "../CommonHeader";
import CustomTable from "../../components/CustomTable";
import ActionDialog from "../../components/ActionDialog";

// material ui
import {
  Paper,
  Container,
} from "@material-ui/core";
import { getRoleList, deleteRole } from '../../api/permission';
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

const header = [
  { id: 'id', label: 'ID', disabledSort: true },
  { id: 'name', label: 'Name', disabledSort: true },
  { id: 'action', label: '', disabledSort: true },
];

const generateBody = (items, page, rowsPerPage, classes, handleRedirect, handleDelete) => {
  return items.map((e, i) => {
    const disableEdit = checkSpecialRole(e.name);

    return {
      id: ((page - 1) * rowsPerPage) + i + 1,
      name: e.name,
      action:
        <div className={classes.actionWrapper}>
          <CustomizeButton label="Edit" active handleClick={handleRedirect(e.id)}/>
          <CustomizeButton label="Delete" disabled={disableEdit} handleClick={handleDelete(e)}/>
        </div>
    }
  })
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    maxWidth: 'fit-content',
    marginLeft: 0,
  },
  tableWrapper: {
    marginTop: 20,
    width: 400,
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    width: '100%',
  },
  editButton: {
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
  },
  deleteButton: {
    background: 'linear-gradient(to right, #ff0000, #ff3131)',
    marginLeft: 10,
  },
  addAccountButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#00a53c',
    width: '100%',
  },
}))

function RoleList(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 4,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'createdAT',
    search: '',
    items: [
      // {id: 2, name: 'Admin'},
      // {id: 3, name: 'Staff'},
      // {id: 4, name: 'Editor'},
    ],
  })
  const [open, setOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({id: '', name: ''});

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleEditRolePage = id => () => {
    props.history.push(`/permission/role/${id}`)
  }

  const handleSetDialog = items => () => {
    setDeleteItem({id: items.id, name: items.name})
    setOpen(true);
  }

  const handleCreateRolePage = useCallback(() => {
    props.history.push('/permission/role/create')
  }, [])

  useEffect(() => {
    getStatusList();
  }, [mainState.page]);

  // API

  const getStatusList = async () => {

    try{
      let obj = {
        limit: mainState.rowsPerPage,
        offset: mainState.page,
      }
      // Get Role List
      const roleListResult = await getRoleList(obj);
      if (!roleListResult.success) {throw 'Fail to get status list'}
      let roleListResultArray = [];
      roleListResult.data.details.role.forEach(e => {
        let obj = {
          id: e.id,
          name: e.name,
        }
        roleListResultArray.push(obj);
      })
      setMainState({items: roleListResultArray, pageItems: roleListResult.data.details.totalRole});
    }
    catch(err) {
      console.log(err)
    }
  };

  const handleDelete = async () => {
    try {
      let input = {roleId: [deleteItem.id]};
      const deleteRepairByResult = await deleteRole(input);
      if (!deleteRepairByResult.success) {throw 'Fail to delete role'}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      getStatusList();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
      console.log(err)
    }
    setOpen(false);
  }

  const body = generateBody(mainState.items, mainState.page, mainState.rowsPerPage, classes, handleEditRolePage, handleSetDialog)

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader
        title={content.roleList.title}
        customComponent={<CustomizeButton label="Create" active handleClick={handleCreateRolePage}/>}
      />

      <Container className={classes.contentContainer}>
        <Paper className={classes.tableWrapper}>
          <CustomTable
            noMinWidth
            headCells={header}
            rows={body}
            page={mainState.page}
            pageItems={mainState.pageItems}
            rowsPerPage={mainState.rowsPerPage}
            order={mainState.order}
            orderBy={mainState.orderBy}
            setState={setMainState}
          />
        </Paper>
      </Container>

      <ActionDialog
        open={open}
        items={deleteItem}
        handleDelete={handleDelete}
        setOpen={setOpen}
      />
    </Container>
  )
}

export default RoleList;
