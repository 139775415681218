import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    marginLeft: 10,
    color: theme.palette.background.button,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background.button}`,
    boxShadow: '0 3px 6px 0 rgba(131, 131, 131, 0.16)',
    fontSize: 12,
    textTransform: 'none',
    padding: '6px 5px',
  },
  buttonProgress: {
    color: theme.palette.background.button,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CustomLoadingButton(props) {
  const {label, loading, component, handleClick=() => {}} = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={loading}
        onClick={handleClick}
      >
        {component && component}
        {label}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}
