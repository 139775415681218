import React from 'react';

// components
import content from "./content.json";
import CustomTextField from '../../components/InputFields/CustomTextField.js';
import CustomSelect from "../../components/InputFields/CustomSelect";

// material ui
import {Grid, Paper} from "@material-ui/core";
import CustomPasswordField from "../../components/InputFields/CustomPasswordField";

export default function ModifyForm(props) {
  const {
    action, email, username, displayName, role, status, assignSpecialRole,
    statusArray, roleArray, repairer, repairerArray,
    handleChange, password, invalidPassword
  } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <CustomTextField
          outlined
          fullWidth
          disabled={action === 0}
          label={content.modifyAccount.inputFlied.username}
          stateKey="username"
          value={username}
          handleChange={handleChange}
        />
      </Grid>

      {!Boolean(action) &&
        <Grid item xs={12} md={6}>
          <CustomTextField
            outlined
            fullWidth
            disabled
            label={content.modifyAccount.inputFlied.email}
            stateKey="email"
            value={email}
            handleChange={handleChange}
          />
        </Grid>
      }

      <Grid item xs={12} md={6}>
        <CustomTextField
          outlined
          fullWidth
          label={content.modifyAccount.inputFlied.displayName}
          stateKey="displayName"
          value={displayName}
          handleChange={handleChange}
        />
      </Grid>

      {action === 1 &&
      <Grid item xs={6}>
        <CustomPasswordField
          fullWidth
          label={content.modifyAccount.inputFlied.password}
          stateKey="password"
          value={password}
          handleChange={handleChange}
          error={invalidPassword}
        />
      </Grid>
      }

      <Grid item xs={12} md={6}>
        <CustomSelect
          fullWidth
          label={content.modifyAccount.inputFlied.role}
          stateKey="role"
          value={role}
          items={roleArray}
          handleChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomSelect
          fullWidth
          label={content.modifyAccount.inputFlied.status}
          stateKey="status"
          value={status}
          items={statusArray}
          handleChange={handleChange}
        />
      </Grid>

      {assignSpecialRole &&
        <Grid item xs={12} md={6}>
          <CustomSelect
            fullWidth
            label={content.modifyAccount.inputFlied.repairer}
            stateKey="repairer"
            value={repairer}
            items={repairerArray}
            handleChange={handleChange}
          />
        </Grid>
      }

    </Grid>
  )
}
