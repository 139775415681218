import React, { useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';

// components
import content from "./content.json";
import CustomTextField from "../../../components/InputFields/CustomTextField";
import MultilineTextField from '../../../components/InputFields/MultilineTextField.js';
import CustomSelect from "../../../components/InputFields/CustomSelect";
import CustomTable from "../../../components/CustomTable";

// material ui
import {
  Container,
  Grid, Paper,
  Typography, Button,
} from "@material-ui/core";
import moment from 'moment';
// icons
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustomizedButtons from '../../../components/InputFields/CustomizedButtons.js';

const header = [
  { id: 'itemName', label: 'Item', disabledSort: true },
  { id: 'itemCode', label: 'Item Code', disabledSort: true },
  { id: 'reason', label: 'Reason', disabledSort: true },
  { id: 'quantity', label: 'Quantity', disabledSort: true },
  { id: 'unitPrice', label: 'Unit Price', disabledSort: true },
  { id: 'totalPrice', label: 'Total Price', disabledSort: true },
  { id: 'action', label: '', disabledSort: true },
];

const generateBody = (items, deposit, handleChangeNormal,handleChange, handleRemove, classes) => {
  const itemArr = items.map((e, i) => ({
    itemName: <CustomTextField
      outlined
      smallPadding
      // autoWidth
      nonMargin
      disabled={(e.itemName === 'LABOUR') || (e.itemName === 'TRANSPORT')}
      placeholder="Item Name"
      stateKey="itemName"
      value={e.itemName}
      handleChange={handleChange(i)}
    />,
    itemCode: <CustomTextField
      autoWidth
      outlined
      smallPadding
      nonMargin
      placeholder={e.label || 'Item Code'}
      stateKey="itemCode"
      value={e.itemCode}
      handleChange={handleChange(i)}
    />,
    reason: <MultilineTextField
      stateKey="remark"
      value={e.remark}
      outlined
      smallPadding
      rows={1}
      rowsMax={1}
      handleChange={handleChange(i)}
    />,
    quantity: <CustomTextField
      autoWidth
      outlined
      smallPadding
      nonMargin
      alignRight
      placeholder="Quantity"
      stateKey="quantity"
      value={e.quantity}
      handleChange={handleChange(i)}
    />,
    unitPrice: <CustomTextField
      autoWidth
      outlined
      smallPadding
      nonMargin
      alignRight
      placeholder="Unit Price"
      stateKey="unitPrice"
      value={e.unitPrice}
      handleChange={handleChange(i)}
    />,
    totalPrice: <CustomTextField
      autoWidth
      outlined
      smallPadding
      nonMargin
      alignRight
      placeholder="Total Price"
      stateKey="totalPrice"
      disabled
      value={(e.quantity * e.unitPrice) || 0}
      handleChange={handleChange(i)}
    />,
    checkBox: !e.label && <HighlightOffIcon className={classes.deleteRows} onClick={handleRemove(i)}/>,
  }));

  let totalSum = 0;
  items.forEach(e => {
    if (parseInt(e.quantity) && parseInt(e.unitPrice)) {
      totalSum += (parseInt(e.quantity) * parseInt(e.unitPrice))
    }
  })

  itemArr.push({
    item: '',
    itemCode: '',
    reason: '',
    quantity: '',
    unitPrice: <Typography className={classes.tableTotal}>Total</Typography>,
    totalPrice: <Typography className={classes.tableTotalAmount}>{totalSum || 0}</Typography>,
    checkBox: ''
  })

  itemArr.push({
    item: '',
    itemCode: '',
    reason: '',
    quantity: '',
    unitPrice: <Typography className={classes.tableTotal}>Deposit</Typography>,
    totalPrice: <CustomTextField
        autoWidth
        outlined
        smallPadding
        nonMargin
        alignRight
        placeholder="Deposit"
        stateKey="depositSupplier"
        value={deposit}
        handleChange={handleChangeNormal}
      />,
    checkBox: ''
  })

  let amount = totalSum ? totalSum - deposit : 0;
  itemArr.push({
    item: '',
    itemCode: '',
    reason: '',
    quantity: '',
    unitPrice: <Typography className={classes.tableTotal}>Amount</Typography>,
    totalPrice: <Typography className={classes.tableTotalAmount}>{amount || 0}</Typography>,
    checkBox: ''
  })

  return itemArr;
};

const useStyles = makeStyles(theme => ({
  spacer: {
    margin: 20,
  },
  tableWrapper: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    marginBottom: 30,
  },
  sectionTitle: {
    textAlign: 'end',
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    fontSize: theme.typography.textFieldLabel.fontSize,
  },
  quotationNoWrapper: {
    marginTop: 30,
    marginBottom: -30,
  },
  tableTotal: {
    textAlign: 'left',
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: '600'
  },
  tableTotalAmount: {
    textAlign: 'right',
    paddingRight: 10,
    fontSize: theme.typography.textFieldLabel.fontSize,
  },
  addButton: {
    fontSize: 12,
    textTransform: 'none',
  },
  deleteRows: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
}));

function QuotationSupplier(props) {
  const { mainState, setMainState, serviceID, handleGenerateCashSale } = props;
  const classes = useStyles();

  const handleChange = useCallback((key,length) => event => {
    if (length && event.target.value.length >= length)  {
      return;
    }
    setMainState({ [key]: event.target.value });
  }, [])

  const handleChangeDynamic = useCallback(index => key => event => {
    const tmpArr = [...mainState.quotationItemsSupplier];
    tmpArr[index][key] = event.target.value;
    setMainState({ quotationItemsSupplier: tmpArr });
  }, [mainState.quotationItemsSupplier]);

  const handleDynamicRemove = useCallback(index => () => {
    const tmpArr = [...mainState.quotationItemsSupplier];
    tmpArr.splice(index, 1);
    setMainState({ quotationItemsSupplier: tmpArr });
  }, [mainState.quotationItemsSupplier])

  const handleAdd = useCallback(() => {
    const tmpArr = [...mainState.quotationItemsSupplier];
    const obj = { itemName: '', itemCode: '', remark: '', quantity: '', unitPrice: '' };
    tmpArr.splice(tmpArr.length - 2, 0, obj);
    setMainState({ quotationItemsSupplier: tmpArr });
  }, [mainState.quotationItemsSupplier])

  const body = generateBody(mainState.quotationItemsSupplier, mainState.depositSupplier, handleChange, handleChangeDynamic, handleDynamicRemove, classes)
  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <Paper className={classes.tableWrapper}>
      <Container  className={classes.contentContainer} style={{marginBottom: 20}}>
      <Grid container spacing={1} style={{marginBottom: 10}}>
          <CustomTextField
            incline
            outlined
            disabled
            label={content.quotation.field_1}
            stateKey="progressUpdateDate"
            value={mainState.cashSaleNoSupplier}
            handleChange={() => {}}
          />
           <CustomizedButtons disabled={mainState.cashSaleNoSupplier} label="Generate" handleClick={() => handleGenerateCashSale(true)}/>
        </Grid>
        <MultilineTextField
          outlined
          fullWidth
          rows={1}
          label={'Notes'}
          placeholder={'Enter Notes'}
          stateKey="notesSupplier"
          value={mainState.notesSupplier}
          textLength={20}
          handleChange={handleChange}
        />
        <div className={classes.spacer} />

        <MultilineTextField
          outlined
          fullWidth
          rows={1}
          label={'Remarks'}
          placeholder={'Enter Remarks'}
          stateKey="quotationCatRemarkSupplier"
          value={mainState.quotationCatRemarkSupplier}
          textLength={80}
          handleChange={handleChange}
        />
    </Container>
        {/* <Grid container spacing={2}>
          <Grid item >
            <CustomTextField
              incline
              outlined
              disabled
              label={content.quotation.field_1}
              stateKey="progressUpdateDate"
              value={encodeCashSale(serviceID)}
              handleChange={() => {}}
            />
          </Grid>
        </Grid>
       <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid item xs={12} md={12}>
            <MultilineTextField
              outlined
              fullWidth
              rows={1}
              label={'Remarks'}
              placeholder={'Enter Remarks'}
              stateKey="notes"
              value={mainState.notes}
              handleChange={handleChange}
            />
          </Grid>
        </Grid> */}

        <CustomTable
          headCells={header}
          rows={body}
          bodyHover
          page={1}
          pageItems={0}
          rowsPerPage={10}
          order="asc"
          orderBy="createdAT"
          setState={setMainState}
        />

      <div className={classes.spacer} />
        <div style={{width: 500}} >
          <MultilineTextField
            outlined
            flexEnd
            fullWidth
            rows={1}
            placeholder={'Enter Quotation Remarks'}
            stateKey="quotationListRemarkSupplier"
            value={mainState.quotationListRemarkSupplier}
            textLength={80}
            handleChange={handleChange}
          />
        </div>
        <div className={classes.spacer} />
        <Button
          variant="contained"
          color="secondary"
          className={classes.addButton}
          onClick={handleAdd}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Item
        </Button>
      </Paper>
    </Container>
  )
}

export default QuotationSupplier;
