import React, {useContext, useCallback, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

// components
import { utilsContext } from '../../contexts';
import content from "./content.json";
import CustomHeader from "../CommonHeader";
import ModifyForm from "./ModifyForm";

// material ui
import {
  Paper,
  Container,
  Button,
} from "@material-ui/core";
import { getRoleList } from '../../api/permission';
import { getUser, createUser } from '../../api/user';
import CustomizeButton from "../../components/InputFields/CustomizedButtons";
import {getRepairBy} from "../../api/taxonomic";
import {checkSpecialRole} from "../../utils";


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 30,
  },
  formWrapper: {
    marginTop: 20,
    padding: 30,
    boxShadow: '0 5px 20px 0 rgba(72, 72, 72, 0.16)',
    borderRadius: 10,
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    },
  },
  buttonWrapper: {
    textAlign: 'end',
  },
  submitButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
    width: 175,
    marginTop: 50,
  }
}))

const statusList = [
  {value: 0, label: 'Disable'},
  {value: 1, label: 'Active'}
]

function CreateUser(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    username: '',
    displayName: '',
    roleArray: [
      {value: 9, label: 'Admin'},
      {value: 10, label: 'Staff'},
      {value: 11, label: 'Deliver Person'},
    ],
    role: 0,
    repairerArray: [],
    repairer: 0,
    status: 0,
    password: '',
    invalidPassword: false,
    assignSpecialRole: false,
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => event => {
    if (key === 'role') {
      for (const role of mainState.roleArray) {
        if (role.value === event.target.value) {
          const checkSpacial = checkSpecialRole(role.label)
          console.log(checkSpacial)
          setMainState({assignSpecialRole: checkSpacial})
        }
      }
    }
    setMainState({ [key]: event.target.value });
  }, [mainState])

  const handleCreateAccountPage = useCallback(() => {
    props.history.push('/account/list')
  }, [])

  // API functions
  const handleCreate = async() => {
    let param = {
      username: mainState.username,
      password: mainState.password,
      displayName: mainState.displayName,
      roleId: mainState.role,
      repairerID: mainState.repairer,
      status: mainState.status
    }

    try {
      // Get create user
      const createUserResult = await createUser(param);
      if (!createUserResult.success) {throw createUserResult.data.message}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      handleCreateAccountPage();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
    }
  }

  const getRoles = async() => {
    try {
      // Get Role List
      const roleListResult = await getRoleList();
      if (!roleListResult.success) {throw 'Fail to get user'}
      let roleListResultArray = [];
      roleListResult.data.details.role.forEach(e => {
        let obj = {
          value: e.id,
          label: e.name,
        }
        roleListResultArray.push(obj);
      })
      setMainState({roleArray: roleListResultArray});
    }
    catch(err) {
      console.log(err)
    }
  }

  const getRepairer = async () => {
    try {
      const repairerList = await getRepairBy();
      if (!repairerList.success) {throw 'Fail to get repairer'}
      let repairerListResultArray = [];
      repairerList.data.details.repairBy.forEach(e => {
        let obj = {
          value: e.id,
          label: e.name,
        }
        repairerListResultArray.push(obj);
      })
      setMainState({repairerArray: repairerListResultArray});
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getRoles();
    getRepairer();
  }, [])

  return (
    <Container fixed maxWidth="lg">
      <CustomHeader title={content.modifyAccount.createTitle} />

      <Paper className={classes.formWrapper}>
        <ModifyForm
          action={1}
          username={mainState.username}
          displayName={mainState.displayName}
          role={mainState.role}
          status={mainState.status}
          password={mainState.password}
          invalidPassword={mainState.invalidPassword}
          roleArray={mainState.roleArray}
          repairer={mainState.repairer}
          repairerArray={mainState.repairerArray}
          assignSpecialRole={mainState.assignSpecialRole}
          statusArray={statusList}
          handleChange={handleChange}
        />

        <div className={classes.buttonWrapper}>
          <CustomizeButton label="Create" active handleClick={handleCreate}/>
        </div>

      </Paper>
    </Container>
  )
}

export default CreateUser;
