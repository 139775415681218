// pages
// Services
import Login from "../pages/Login";
import Dashboard from '../pages/Dashboard';
import CreateServices from '../pages/services/CreateServices';
import EditServices from '../pages/services/EditServices';
import Index from '../pages/services/ServiceList';
// Accounts
import UserDetails from '../pages/accounts/UserDetails';
import UserList from '../pages/accounts/UserList';
import EditUser from '../pages/accounts/EditUser'
import CreateUser from "../pages/accounts/CreateUser";
// Role Permission
import RoleList from "../pages/permissions/RoleList";
import EditRole from "../pages/permissions/EditRole";
import CreateRole from "../pages/permissions/CreateRole";
import PermissionsList from "../pages/permissions/PermissionList";
// Taxonomic
import WorkerList from "../pages/taxonomic/WorkerList";
import CustomerList from "../pages/taxonomic/CustomerList";
import RepairByList from "../pages/taxonomic/RepairByList";
// import InsuranceCompanyList from "../pages/taxonomic/InsuranceCompanyList(removed)";
import RepairProgressList from "../pages/taxonomic/RepairProgressList";
// import QuotationCategoryList from "../pages/taxonomic/QuatationCategoryList(removed)";
import WarrantyTypeList from "../pages/taxonomic/WarrantyTypeList";
import CompleteStatusList from "../pages/taxonomic/CompleteStatusList";
// Commission
import Commission from '../pages/commission/index';
import SupplierList from "../pages/taxonomic/SupplierList";
import ProductBrandList from "../pages/taxonomic/ProductBrandList";
import ProductTypeList from "../pages/taxonomic/ProductTypeList";
import WorkProcess from "../pages/taxonomic/WorkProcess";

const routes = {
  generals: [
    {
      text: "Login",
      path: "/login",
      exact: true,
      component: Login,
    },
  ],
  navBar: [
    {
      text: "Dashboard",
      path: "/",
      exact: true,
      icon: "fa-cart-line",
      component: Dashboard
    },
    {
      text: "Services",
      path: "/service",
      exact: true,
      icon: "fa-file-alt",
      component: Index,
      // children: [
        // {
        //   text: "Service List",
        //   path: "/",
        //   exact: true,
        //   icon: "fa-file-alt",
        //   component: Index,
        // },
        // {
        //   text: "Submit Service",
        //   path: "/create",
        //   icon: "fa-file-medical",
        //   component: CreateServices,
        //   hidden: true,
        // },
        // {
        //   text: "Edit Service",
        //   path: "/:serviceID",
        //   exact: true,
        //   component: EditServices,
        //   hidden: true,
        // },
      // ]
    },
    {
      text: "Submit Service",
      path: "/service/create",
      icon: "fa-file-medical",
      component: CreateServices,
      hidden: true,
    },
    {
      text: "Edit Service",
      path: "/service/:serviceID",
      exact: true,
      component: EditServices,
      hidden: true,
    },
    {
      text: "Referral Commission",
      path: "/referral-commission",
      icon: "fa-money-check-alt",
      checkPermission: true,
      component: Commission,
    },
    {
      text: "Taxonomy",
      path: "/taxonomy",
      icon: "fa-tags",
      checkPermission: true,
      children: [
        {
          text: "Technicians",
          path: "/worker",
          exact: true,
          icon: "fa-tag",
          component: WorkerList,
        },
        // {
        //   text: "Customers",
        //   path: "/customer",
        //   icon: "fa-tag",
        //   component: CustomerList,
        // },
        {
          text: "Repairer",
          path: "/repair-by",
          icon: "fa-tag",
          component: RepairByList,
        },
        {
          text: "Repair Progress",
          path: "/repair-progress",
          icon: "fa-tag",
          component: RepairProgressList,
        },
        {
          text: "Supplier",
          path: "/supplier",
          icon: "fa-tag",
          component: SupplierList,
        },
        {
          text: "Product Brand",
          path: "/product-brand",
          icon: "fa-tag",
          component: ProductBrandList,
        },
        // {
        //   text: "Insurance Company",
        //   path: "/insurance-company",
        //   icon: "fa-tag",
        //   component: InsuranceCompanyList,
        // },
        // {
        //   text: "Quotation Category",
        //   path: "/quotation-category",
        //   icon: "fa-tag",
        //   component: QuotationCategoryList,
        // },
        {
          text: "Warranty Type",
          path: "/warranty-type",
          icon: "fa-tag",
          component: WarrantyTypeList,
        },
        {
          text: "Product Type",
          path: "/product-type",
          icon: "fa-tag",
          component: ProductTypeList,
        },
        {
          text: "Complete Status",
          path: "/complete-status",
          icon: "fa-tag",
          component: CompleteStatusList,
        },
        {
          text: "Work Process",
          path: "/work-process",
          icon: "fa-tag",
          component: WorkProcess,
        }
      ]
    },
    {
      text: "Account",
      path: "/account",
      icon: "fa-users-cog",
      children: [
        {
          text: "Account Details",
          path: "/",
          exact: true,
          icon: "fa-user",
          component: UserDetails,
        },
        {
          text: "Account List",
          path: "/list",
          icon: "fa-users",
          checkPermission: true,
          component: UserList,
        },
        {
          text: "Create Account",
          path: "/create",
          component: CreateUser,
          hidden: true,
        },
        {
          text: "Modify Account",
          path: "/:userID",
          component: EditUser,
          hidden: true,
        },
      ]
    },
    {
      text: "Permissions",
      path: "/permission",
      checkPermission: true,
      icon: "fa-user-tag",
      children: [
        {
          text: "Role List",
          path: "/role",
          exact: true,
          icon: "fa-list",
          component: RoleList,
        },
        {
          text: "Create Role",
          path: "/role/create",
          exact: true,
          component: CreateRole,
          hidden: true,
        },
        {
          text: "Edit Role",
          path: "/role/:roleID",
          exact: true,
          component: EditRole,
          hidden: true,
        },
        {
          text: "Permission List",
          path: "/",
          exact: true,
          icon: "fa-list",
          component: PermissionsList,
        }
      ]
    }
  ]
}


export {routes}
