import React from "react";
import ReactExport from "react-data-export";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const titleStyle = {
  fill: {
    patternType: "solid",
    fgColor: {rgb: "C0C0C0"}
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    top: { style: 'thin', color: '000000' },
    bottom: { style: 'thin', color: '000000' },
    left: { style: 'thin', color: '000000' },
    right: { style: 'thin', color: '000000' }
  }
};

const columnStyle = {
  border: {
    top: { style: 'thin', color: '000000' },
    bottom: { style: 'thin', color: '000000' },
    left: { style: 'thin', color: '000000' },
    right: { style: 'thin', color: '000000' }
  }
}

function ExportExcel(props) {
  const {items} = props;

  const dataItem = items.map((e, i) => ([
    {value: i+1, style: columnStyle},
    {value: e.jobSheetNumber1, style: columnStyle},
    {value: e.jobSheetNumber2, style: columnStyle},
    {value: e.jobSheetNumber3, style: columnStyle},
    {value: e.keyInDate ? moment(e.keyInDate).format('DD-MM-YYYY') : '', style: columnStyle},
    {value: e.jobDate ? moment(e.jobDate).format('DD-MM-YYYY') : '', style: columnStyle},
    {value: e.customerName, style: columnStyle},
    {value: e.customerPhoneNumber, style: columnStyle},
    {value: e.customerPhoneNumber2, style: columnStyle},
    {value: e.customerPhoneNumber3, style: columnStyle},
    {value: e.customerAddress, style: columnStyle},
    {value: e.productBrand, style: columnStyle},
    {value: e.modelCode, style: columnStyle},
    {value: e.repairer, style: columnStyle},
    {value: e.supplier, style: columnStyle},
    {value: e.productType, style: columnStyle},
    {value: e.serialNumber, style: columnStyle},
    {value: e.secondSerialNumber, style: columnStyle},
    {value: e.repairIn90Day, style: columnStyle},
    {value: e.purchaseDate ? moment(e.purchaseDate).format('DD-MM-YYYY') : '', style: columnStyle},
    {value: e.purchasePrice, style: columnStyle},
    {value: e.warrantyType, style: columnStyle},
    {value: e.serviceRepairProgressStatus, style: columnStyle},
    {value: e.progressDuration, style: columnStyle},
    {value: e.progressCreateTime ? moment(e.progressCreateTime).format('DD-MM-YYYY') : '', style: columnStyle},
    {value: e.serviceCompleteStatus, style: columnStyle},
    {value: e.repairDuration, style: columnStyle},
    {value: e.completeCreateTime ? moment(e.completeCreateTime).format('DD-MM-YYYY') : '', style: columnStyle},
    {value: e.duration, style: columnStyle},
    {value: e.totalQuotationAmount, style: columnStyle},
    {value: e.totalQuotationAmountSupplier, style: columnStyle},
    {value: e.deposit, style: columnStyle},
    {value: e.depositSupplier, style: columnStyle},
    {value: (e.totalQuotationAmount - e.deposit) || 0, style: columnStyle},
    {value: (e.totalQuotationAmountSupplier - e.depositSupplier) || 0, style: columnStyle},
  ]))

  const multiDataSet = [
    {
      columns: [
        {title: "No", style: titleStyle, width: {wpx: 30}},
        {title: "Job Sheet No", style: titleStyle, width: {wpx: 150}},
        {title: "Job Sheet No 2", style: titleStyle, width: {wpx: 150}},
        {title: "Job Sheet No 3", style: titleStyle, width: {wpx: 150}},
        {title: "Create Date", style: titleStyle},
        {title: "Receive data", style: titleStyle},
        {title: "Customer Name", style: titleStyle, width: {wpx: 270}},
        {title: "Customer Phone Number", style: titleStyle, width: {wpx: 220}},
        {title: "Customer Phone Number 2", style: titleStyle, width: {wpx: 220}},
        {title: "Customer Phone Number 3", style: titleStyle, width: {wpx: 220}},
        {title: "Customer Address", style: titleStyle, width: {wpx: 770}},
        {title: "Product Brand", style: titleStyle, width: {wpx: 100}},
        {title: "Model", style: titleStyle, width: {wpx: 120}},
        {title: "Repairer", style: titleStyle, width: {wpx: 80}},
        {title: "Supplier", style: titleStyle},
        {title: "Product Type", style: titleStyle, width: {wpx: 100}},
        {title: "Serial Number", style: titleStyle, width: {wpx: 120}},
        {title: "Serial Number 2", style: titleStyle, width: {wpx: 120}},
        {title: "Repair in 90 Days", style: titleStyle, width: {wpx: 120}},
        {title: "Date of Purchase", style: titleStyle, width: {wpx: 120}},
        {title: "Purchase Price", style: titleStyle, width: {wpx: 120}},
        {title: "Warranty Type", style: titleStyle, width: {wpx: 120}},
        {title: "Repair Progress", style: titleStyle, width: {wpx: 180}},
        {title: "Progress Duration", style: titleStyle, width: {wpx: 120}},
        {title: "Repair Progress Update Date", style: titleStyle, width: {wpx: 200}},
        {title: "Complete Status", style: titleStyle, width: {wpx: 180}},
        {title: "Repair Duration", style: titleStyle, width: {wpx: 180}},
        {title: "Complete Status Update Date", style: titleStyle, width: {wpx: 200}},
        {title: "Duration", style: titleStyle},
        {title: "Total Amount (Customer)", style: titleStyle, width: {wpx: 200}},
        {title: "Total Amount (Supplier)", style: titleStyle, width: {wpx: 200}},
        {title: "Deposit (Customer)", style: titleStyle, width: {wpx: 200}},
        {title: "Deposit (Supplier)", style: titleStyle, width: {wpx: 200}},
        {title: "Total Quotation Amount (Customer)", style: titleStyle, width: {wpx: 220}},
        {title: "Total Quotation Amount (Supplier)", style: titleStyle, width: {wpx: 220}},
      ],
      data: dataItem
    }
  ];

  return (
    <ExcelFile hideElement={true}>
      <ExcelSheet dataSet={multiDataSet} name="Organization"/>
    </ExcelFile>
  )
}

export default ExportExcel;
