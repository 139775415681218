import React, {useState, useContext, useEffect, useCallback, useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';

// components
import content from './content.json';
import { utilsContext } from '../../../contexts';
import { getServiceList, updateMultipleService } from '../../../api/service.js';
import { getRepairBy, getRepairProgressStatus, getWarrantyTypes, getSupplier, getCompleteStatusList } from '../../../api/taxonomic.js'
import useLocalQuery from "../../../hooks/useLocalQuery";
import CustomHeader from "../../CommonHeader";
import CustomTable from '../../../components/CustomTable.js';
import CustomDatePickers from '../../../components/InputFields/CustomDatePickers.js';
import CustomTextField from '../../../components/InputFields/CustomTextField.js';
import CustomSelect from "../../../components/InputFields/CustomSelect";
import CustomizeButton from "../../../components/InputFields/CustomizedButtons";

// material ui
import {
  Container,
  Paper,
  Grid,
  Checkbox,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import {checkSpecialRole} from "../../../utils";

const generateBody = (items, page, rowsPerPage, handleCheck, classes) => {
  return items.map((e, i) => ({
    id: ((page - 1) * rowsPerPage) + i + 1,
    real_id: e.id,
    jobSheetNumber: e.number1,
    jobSheetNumber1: e.number2,
    jobSheetNumber2: e.number3,
    supplier: e.supplier,
    model: e.modelCode,
    warrantyType: e.warrantyType,
    repairBy: e.repairBy,
    repairProgress: e.serviceRepairProgressStatus,
    progressDuration: e.progressDuration,
    completeStatus: e.serviceCompleteStatus,
    repairDuration: e.repairDuration,
    duration: e.duration,
    checkBox: <Checkbox className={classes.tableCheckbox} checked={e.checked} onChange={handleCheck(i)} />,
  }));
};

const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
  },
  filterWrapper: {
    margin: '0 0 15px 0',
    backgroundColor: 'transparent',
    position: 'relative',
  },
  actionWrapper: {
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      position: 'initial',
    },
  },
  bulkActionWrapper: {
    margin: '2px 0',
    width: 'fit-content',
  },
  tableWrapper: {
  },
  tableCheckbox: {
    '& .MuiSvgIcon-root': {
      width: '0.8em',
    }
  },
  selectGroupWrapper: {
    padding: '0 14px',
  },
  clickText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.secondary.main,
    }
  },
  addButton: {
    backgroundColor: theme.palette.background.button,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.background.button}`,
    fontSize: 12,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.main,
    },
  },
  buttonOptionGroup: {
    width: theme.inputs.width,
  },
  buttonOption: {
    marginRight: 10,
  },
  buttonAction: {
    marginLeft: 20,
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    color: theme.palette.primary.contrastText,
    flex: 1,
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      flex: '.3',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '.5',
    },
  },
//  common
  displayFlex: {
    display: 'flex',
  },
  flexJustifyCenter: {
    justifyContent: 'center'
  },
  flexJustifyBetween: {
    justifyContent: 'space-between'
  },
  flexAlignCenter: {
    alignItems: 'center'
  },
  flexStart: {
    justifyContent: ''
  }
}));

function Index(props) {
  const classes = useStyles();
  const permissions = localStorage.getItem("permissions");
  const role = localStorage.getItem("role");
  const repairer = localStorage.getItem("repairer");
  const isSpecialRole = checkSpecialRole(role);
  const permissionArray = permissions.split(",");
  const { _handleChange } = useContext(utilsContext);
  const [currentLocalState, setLocalState] = useLocalQuery();
  const [mainState, _setMainState] = useState({
    page: (currentLocalState && currentLocalState.offset) ? parseInt(currentLocalState.offset) : 1,
    pageItems: 0,
    rowsPerPage: 30,
    order: (currentLocalState && currentLocalState.orderType) || 'desc',
    orderBy: (currentLocalState && currentLocalState.orderBy) || 'createdAt',
    startDate: (currentLocalState && currentLocalState.startDate) || moment().subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: (currentLocalState && currentLocalState.endDate) || moment().format('YYYY-MM-DD'),
    completeStartDate: (currentLocalState && currentLocalState.completeStartDate) || moment().subtract(1, 'months').format('YYYY-MM-DD'),
    completeEndDate: (currentLocalState && currentLocalState.completeEndDate) || moment().format('YYYY-MM-DD'),
    jobSheetNumber: (currentLocalState && currentLocalState.number) || '',
    supplier: (currentLocalState && currentLocalState.supplierId) || 0,
    customerNumber: (currentLocalState && currentLocalState.customerNumber) || '',
    cashSaleNumber: (currentLocalState && currentLocalState.cashSale) || '',
    repairProgress: (currentLocalState && currentLocalState.repairProgressStatusId) || 0,
    updateRepairProgress: 0,
    warrantyType: (currentLocalState && currentLocalState.warrantyTypeId) || 0,
    repairBy: isSpecialRole ? repairer  : (currentLocalState && currentLocalState.repairById) || 0,
    issuedJobBy: 0,
    items: [],
    supplierItems: [],
    completeStatusItem: [
      {label: 'Complete', value: 3},
      {label: 'Incomplete', value: 4}
    ],
    completeStatus: (currentLocalState && currentLocalState.completeStatusId) || 0,
    completeStatusListItem: [],
    repairProgressItems: [],
    warrantyTypeItems: [],
    repairByItems: [],
    checkAll: false,
    getCount: 0,
    completeActive: (currentLocalState && currentLocalState.completeActive) ? currentLocalState.completeActive : 0,
    uncompleteActive: (currentLocalState && currentLocalState.completeActive) ? !currentLocalState.completeActive : 1,
  });

  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRedirect = id => () => {
    props.history.push(`/service/${id}`)
  }

  const handleCheckAll = event => {
    const tmpBody = [...mainState.items];
    tmpBody.forEach(e => {
      e.checked = event.target.checked;
    })
    setMainState({ items: tmpBody, checkAll: event.target.checked })
  }

  const handleCheck = index => event =>{
    const {items} = mainState;
    const tmpBody = [...items];
    tmpBody[index].checked = event.target.checked;
    setMainState({ items: tmpBody, checkAll: false })
  }

  const handleChangeComplete = section => () => {
    if (section === 0) {
      setMainState({ completeActive: true, uncompleteActive: false })
    } else {
      setMainState({ completeActive: false, uncompleteActive: true })
    }
  }

  const handleChange = key => event => {

    setMainState({ [key]: event.target.value });
  }

  const handleDateChange = key => event => {
    let value = event;
    setMainState({ [key]: value  });
  }

  const handleCreatePageRedirect = () => {
    props.history.push('/service/create')
  }

  const handleReset = async () => {
    const defaultObj = {
      page: 1,
      order: 'desc',
      orderBy: 'createdAt',
      rowsPerPage: 30,
      startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      completeStartDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      completeEndDate: moment().format('YYYY-MM-DD'),
      jobSheetNumber: '',
      supplier: 0,
      customerNumber: '',
      cashSaleNumber: '',
      repairProgress: 0,
      warrantyType: 0,
      completeStatus: 0,
      repairBy: isSpecialRole ? repairer  : (currentLocalState && currentLocalState.repairById) || 0,
      completeActive: false,
      uncompleteActive: true,
    }
    setMainState(defaultObj)

    await getServices(defaultObj);
  }

  const keyPress = (e) => {
    if(e.keyCode === 13) getServices();
  };

  const handleSearch = async () => {
    setMainState({page: 1})
    await getServices();
  }

  // API functions
  const getServices = async (obj) => {
    _handleChange({ openBackdrop: true });
    const { getCount } = mainState;

    let startDate, endDate, completeStartDate, completeEndDate,
      jobSheetNumber, supplier, completeActive, customerNumber, cashSaleNumber,
      repairProgress, warrantyType, completeStatus, repairBy,
      rowsPerPage, page, order, orderBy;

    if (obj) {
      startDate = obj.startDate;
      endDate = obj.endDate;
      completeStartDate = obj.completeStartDate;
      completeEndDate = obj.completeEndDate;
      jobSheetNumber = obj.jobSheetNumber;
      supplier = obj.supplier;
      completeActive = obj.completeActive;
      customerNumber = obj.customerNumber;
      cashSaleNumber = obj.cashSaleNumber;
      repairProgress = obj.repairProgress;
      warrantyType = obj.warrantyType;
      completeStatus = obj.completeStatus;
      repairBy = obj.repairBy;
      rowsPerPage = obj.rowsPerPage;
      page = obj.page ? obj.page : 1 ;
      order = obj.order;
      orderBy = obj.orderBy;
    } else {
      startDate = mainState.startDate;
      endDate = mainState.endDate;
      completeStartDate = mainState.completeStartDate;
      completeEndDate = mainState.completeEndDate;
      jobSheetNumber = mainState.jobSheetNumber;
      supplier = mainState.supplier;
      completeActive = mainState.completeActive;
      customerNumber = mainState.customerNumber;
      cashSaleNumber = mainState.cashSaleNumber;
      repairProgress = mainState.repairProgress;
      warrantyType = mainState.warrantyType;
      completeStatus = mainState.completeStatus;
      repairBy = mainState.repairBy;
      rowsPerPage = mainState.rowsPerPage;
      page = mainState.page;
      order = mainState.order;
      orderBy = mainState.orderBy;
    }

    const params = {
      offset: page,
      limit: rowsPerPage,
      orderBy: orderBy === 'real_id' ? 'id' : orderBy,
      orderType: order,
      startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
      endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
      number: jobSheetNumber,
      customerNumber: customerNumber,
      cashSale: cashSaleNumber,
      supplierId: supplier === 0 ? null : supplier,
      completeStatusId: completeActive ? completeStatus : null,
      repairProgressStatusId: repairProgress,
      repairById: repairBy === 0 ? null : repairBy,
      warrantyTypeId: warrantyType === 0 ? null : warrantyType,
    };
    //to switch complete data
    params.completeActive = completeActive;
    if (completeActive) {
      params.completeStartDate = completeStartDate && moment(completeStartDate).format('YYYY-MM-DD');
      params.completeEndDate = completeEndDate && moment(completeEndDate).format('YYYY-MM-DD');
    }

    const result = await getServiceList(params);
    const { success, data } = result;
    const failObj = {};
    if (success) {
      const itemArr = data.details.serviceList.map(e => ({
        id: e.id,
        number1: e.number1,
        number2: e.number2,
        number3: e.number3,
        modelCode: e.modelCode,
        warrantyType: e.warrantyType,
        supplier: e.supplier,
        serviceCompleteStatus: e.serviceCompleteStatus,
        serviceRepairProgressStatus: e.serviceRepairProgressStatus,
        repairBy: e.repairBy,
        progressDuration: e.progressDuration + 'day(s)',
        repairDuration: e.repairDuration + 'day(s)',
        duration: (e.duration || 0) + 'day(s)',
        checked: false,
      }))
      setMainState({ items: itemArr, pageItems: data.details.totalServiceCount, checkAll: false })
    } else {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = 'Fail to get service list';
    }

    _handleChange({ openBackdrop: false, ...failObj });
    if (getCount !== 0) setLocalState(params);
  }

  const getSelectList = async () => {
    _handleChange({ openBackdrop: true });

    const failObj = {};
    let supplierList = [...mainState.supplierItems];
    let repairByList = [...mainState.repairByItems];
    let repairProgressList = [...mainState.repairProgressItems]
    let warrantyTypeList = [...mainState.warrantyTypeItems]
    let completeStatusList = [...mainState.completeStatusListItem]

    try {
      // Get Supplier List
      const supplierResult = await getSupplier();
      if (!supplierResult.success) {throw 'Fail to get supplier list'}
      supplierResult.data.details.supplier.forEach(e => {
        supplierList.push({ label: e.name, value: e.id })
      })

      // Get Repair By List
      const repairByResult = await getRepairBy();
      if (!repairByResult.success) {throw 'Fail to get repair by list'}
      repairByResult.data.details.repairBy.forEach(e => {
        repairByList.push({ label: e.name, value: e.id })
      })

      // Get Repair Progress List
      const repairProgressResult = await getRepairProgressStatus();
      if (!repairProgressResult.success) {throw 'Fail to get repair progress list'}
      repairProgressResult.data.details.repairProgressStatus.forEach(e => {
        repairProgressList.push({ label: e.name, value: e.id })
      })

        // Get Complete Status List
        const completeStatusResult = await getCompleteStatusList();
        if (!completeStatusResult.success) {throw 'Fail to get repair progress list'}
        completeStatusResult.data.details.completeStatus.forEach(e => {
          completeStatusList.push({ label: e.name, value: e.id })
        })


      // Get Warranty Type
      const warrantyTypeResult = await getWarrantyTypes();
      if (!warrantyTypeResult.success) {throw 'Fail to get warranty type list'}
      warrantyTypeResult.data.details.warrantyType.forEach(e => {
        warrantyTypeList.push({ label: e.name, value: e.id })
      })

    } catch (err) {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = err;
    }

    setMainState({
      supplierItems: supplierList,
      repairByItems: repairByList,
      repairProgressItems: repairProgressList,
      warrantyTypeItems: warrantyTypeList,
      completeStatusListItem: completeStatusList,
    })

    _handleChange({ openBackdrop: false});
  }

  const handleBulkUpdate = async () => {
    const {items, updateRepairProgress} = mainState;

    if (updateRepairProgress === 0) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: 'Please select a status',
      })
      return;
    }

    const updateArr = [];
    items.forEach(e => {
      if (e.checked) {
        updateArr.push(e.id);
      }
    })

    if (updateArr.length === 0) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: 'No item was selected',
      })
      return;
    }

    _handleChange({ openBackdrop: true });

    const { success, data } = await updateMultipleService({
      serviceIds: updateArr,
      completeStatusId: updateRepairProgress
    });

    const snackBarObj = {};
    if (success) {
      snackBarObj.snackbarType = 0;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = 'Update Successful'
    } else {
      snackBarObj.snackbarType = 1;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = data.message;
    }

    _handleChange({ openBackdrop: false, ...snackBarObj })
    await getServices();
  }

  useEffect(() => {
    async function getServicesTriggers() {
      await getServices();
    }
    getServicesTriggers();
  }, []);

  useEffect(() => {
    async function getServicesTriggers() {
      await getServices();
    }
    setMainState({getCount: mainState.getCount+1});
    getServicesTriggers();
  }, [mainState.page, mainState.rowsPerPage, mainState.orderBy, mainState.order])

  useEffect(() => {
    async function getServicesTriggers() {
      await getSelectList();
    }
    getServicesTriggers();
  }, [])

  const header = useMemo(() => [
      { id: 'id', label: 'ID', disabledSort: true },
      { id: 'real_id', label: 'HIDE' },
      { id: 'jobSheetNumber', label: 'JS No' },
      { id: 'jobSheetNumber1', label: 'JS No 2' },
      { id: 'jobSheetNumber2', label: 'JS No 3' },
      { id: 'supplier', label: 'Supplier' },
      { id: 'model', label: 'Model' },
      { id: 'warrantyType', label: 'Warranty Type' },
      { id: 'repairBy', label: 'Repair By' },
      { id: 'repairProgress', label: 'Repair Progress' },
      { id: 'progressDuration', label: 'Progress Duration' },
      { id: 'completeStatus', label: 'Complete Status' },
      { id: 'repairDuration', label: 'Repair Duration' },
      { id: 'duration', label: 'Duration' },
      { id: 'checkBox', disabledSort: true, label:  <Checkbox className={classes.tableCheckbox} checked={mainState.checkAll} color="secondary" onChange={handleCheckAll} /> },
    ], [mainState.checkAll, mainState.items])

  const body = generateBody(mainState.items, mainState.page, mainState.rowsPerPage, handleCheck, classes)

  const CreateButton = () => {
    return (
      <Button
        variant="contained"
        className={classes.addButton}
        onClick={handleCreatePageRedirect}
      >
        Create New Service
      </Button>
    )
  }

  const hasCreatePermission = permissionArray.includes('/service/create');

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader
        title={content.title}
        excel
        details={mainState}
        customComponent={ hasCreatePermission && <CreateButton />}
      />

      <Container  className={classes.contentContainer}>
        <Paper className={classes.filterWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CustomDatePickers
                label={content.inputFlied.startDate}
                incline
                flexEnd
                stateKey="startDate"
                value={mainState.startDate}
                handleChange={handleDateChange}
                handleTextChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomDatePickers
                label={content.inputFlied.endDate}
                incline
                flexEnd
                stateKey="endDate"
                value={mainState.endDate}
                handleChange={handleDateChange}
                handleTextChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CustomTextField
                outlined
                incline
                label={content.inputFlied.jobSheetNumber}
                placeholder={content.inputFlied.jobSheetNumber}
                stateKey="jobSheetNumber"
                keyPress={keyPress}
                value={mainState.jobSheetNumber}
                handleChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomSelect
                incline
                label={content.inputFlied.supplier}
                stateKey="supplier"
                value={mainState.supplier}
                items={mainState.supplierItems}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CustomSelect
                incline
                label={content.inputFlied.warrantyType}
                stateKey="warrantyType"
                value={mainState.warrantyType}
                items={mainState.warrantyTypeItems}
                handleChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <CustomSelect
                incline
                disabled={isSpecialRole}
                label={content.inputFlied.repairBy}
                stateKey="repairBy"
                value={mainState.repairBy}
                items={mainState.repairByItems}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CustomTextField
                outlined
                incline
                label={content.inputFlied.customerNumber}
                placeholder={content.inputFlied.customerNumberPlaceholder}
                stateKey="customerNumber"
                value={mainState.customerNumber}
                handleChange={handleChange}
                keyPress={keyPress}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomTextField
                outlined
                incline
                label={content.inputFlied.CashSale}
                placeholder={content.inputFlied.CashSalePlaceholder}
                stateKey="cashSaleNumber"
                value={mainState.cashSaleNumber}
                handleChange={handleChange}
                keyPress={keyPress}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <div className={clsx(classes.displayFlex, classes.flexAlignCenter)}>
                <Typography className={classes.fieldLabel}> Status</Typography>
                <div className={clsx(classes.displayFlex, classes.buttonOptionGroup)}>
                  <CustomizeButton
                    label="Complete"
                    nonSpacing
                    active={mainState.completeActive}
                    handleClick={handleChangeComplete(0)}
                  />
                  <CustomizeButton
                    label="Incomplete"
                    nonSpacing
                    active={mainState.uncompleteActive}
                    handleClick={handleChangeComplete(1)}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          {mainState.completeActive ?
            <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CustomSelect
                incline
                label={content.inputFlied.completeStatus}
                stateKey="completeStatus"
                value={mainState.completeStatus}
                items={mainState.completeStatusListItem}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          : <div></div>
          }

          {mainState.completeActive ?
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <CustomDatePickers
                  label={content.inputFlied.completeStartDate}
                  incline
                  flexEnd
                  stateKey="completeStartDate"
                  value={mainState.completeStartDate}
                  handleChange={handleDateChange}
                handleTextChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <CustomDatePickers
                  label={content.inputFlied.completeEndDate}
                  incline
                  stateKey="completeEndDate"
                  value={mainState.completeEndDate}
                  handleChange={handleDateChange}
                handleTextChange={handleChange}
                />
              </Grid>
            </Grid>
            :<div></div>
          }

          {mainState.uncompleteActive ?
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <CustomSelect
                  incline
                  label={content.inputFlied.repairProgress}
                  stateKey="repairProgress"
                  value={mainState.repairProgress}
                  items={mainState.repairProgressItems}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            :<div></div>
          }

          <div className={clsx(classes.displayFlex, classes.actionWrapper)}>
            <CustomizeButton label="Search" active handleClick={handleSearch}/>
            <CustomizeButton label="Reset" handleClick={handleReset}/>
          </div>
        </Paper>

        <Divider />
        {permissionArray.includes('/service-details/update/6') &&
          <div className={clsx(classes.bulkActionWrapper, classes.displayFlex)}>
            <CustomSelect
              incline
              label={content.inputFlied.bulkUpdate}
              stateKey="updateRepairProgress"
              value={mainState.updateRepairProgress}
              items={mainState.completeStatusListItem}
              handleChange={handleChange}
            />
            <CustomizeButton label="Apply" handleClick={handleBulkUpdate}/>
          </div>
        }

        <Divider />

        <Paper className={classes.tableWrapper}>
          <CustomTable
            headCells={header}
            rows={body}
            showRowSelectionTextOnly
            isListPagination
            page={mainState.page}
            pageItems={mainState.pageItems}
            rowsPerPage={mainState.rowsPerPage}
            handleRowClick={handleRedirect}
            order={mainState.order}
            orderBy={mainState.orderBy}
            setState={setMainState}
            itemAlignLeft
          />
        </Paper>
      </Container>
    </Container>
  )
}

export default Index;
