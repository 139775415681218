import React, { useState, useContext, useCallback } from 'react';
import {Redirect} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

// component
import { utilsContext } from '../contexts';
import variables from '../configs/variables.json';
import { userLogin } from "../api/user.js";

// material ui
import {
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

// icons
import CustomPasswordField from "../components/InputFields/CustomPasswordField";
import CustomTextField from "../components/InputFields/CustomTextField";

// assets
import loginBackground from "../assets/login-background-min.png";

const useStyles = makeStyles(theme => ({
  spacing: {
    margin: 10
  },
  wrapper: {
    backgroundImage: `url(${loginBackground}), linear-gradient(to bottom, #1bbe8f2b, #24b5b8c7)`,
    backgroundBlendMode: 'overlay',
    backgroundSize: 'cover',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  sections: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 0',
    minHeight: 300,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: 300,
    },
  },
  fieldWrapper: {
    width: '75%',
  },
  title: {
    color: theme.palette.primary.main
  },
  buttonWrapper: {
    textAlign: 'center',
  },
  checkLabel: {
    marginTop: '-15px',
    '& .MuiFormControlLabel-label': {
      color: theme.palette.secondary.main,
      fontSize: 14,
    },
    '& .MuiSvgIcon-root': {
      width: '0.8em',
    }
  },
  submitButton: {
    width: '100%',
    minWidth: 200,
    marginTop: 30,
    color: '#ffffff',
    backgroundColor: theme.palette.background.navBar,
    '&:hover': {
      backgroundColor: 'rgb(44 59 78 / 82%)',
    }
  }
}));

function Login() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [redirect, setRedirect] = useState(false);
  const [mainState, _setMainState] = useState({
    email: '',
    password: '',
    remember: false,
    invalidLogin: false,
  });

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = prop => event => {
    setMainState({ [prop]: event.target.value });
  };

  const handleCheck = prop => event => {
    setMainState({ [prop]: event.target.checked });
  }

  const keyPress = useCallback((e) => {
    setMainState({invalidLogin: false});
    if(e.keyCode === 13) login();
  }, [mainState.email, mainState.password]);

  const login = useCallback(async () => {
    const { email, password, remember } = mainState;
    _handleChange({openBackdrop: true});

    const { success, data } = await userLogin({username: email, password, remember})

    let snackbar = false, snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbar = true;
      snackbarContent = 'Welcome back ' + data.details.displayName;
      localStorage.setItem("userName", data.details.displayName);
      localStorage.setItem("role", data.details.roleName);
      localStorage.setItem("repairer", data.details.repairer);
      localStorage.setItem("userID", data.details.ID);
      localStorage.setItem("permissions", data.details.permission);
      localStorage.setItem("handleSearchParam", null);
      setRedirect(true)
    } else {
      setMainState({invalidLogin: 'Wrong username or password'});
    }

    _handleChange({
      openBackdrop: false,
      snackbar,
      snackbarType,
      snackbarContent
    });
  }, [mainState.email, mainState.password]);


  return (
    <div className={classes.wrapper}>

      {redirect && <Redirect to="/" push/>}

      <h1 className={classes.title}>{variables.siteName}</h1>
      <Paper className={classes.sections}>
        <div className={classes.fieldWrapper}>
          <CustomTextField
            outlined
            fullWidth
            labelColor
            label="Username"
            stateKey="email"
            value={mainState.email}
            handleChange={handleChange}
            keyPress={keyPress}
          />

          <CustomPasswordField
            fullWidth
            labelColor
            label="Password"
            stateKey="password"
            value={mainState.password}
            handleChange={handleChange}
            keyPress={keyPress}
            error={mainState.invalidLogin}
          />


          <FormControlLabel
            className={classes.checkLabel}
            control={<Checkbox checked={mainState.remember} onChange={handleCheck('remember')} name="remember" />}
            label="Remember password"
          />

          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              className={clsx(classes.submitButton)}
              onClick={login}
              disableElevation
            >
              Login
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default Login;
